import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Overview/index.ts";
import { FaBook, FaClipboardList, FaShoePrints, FaBroadcastTower, FaQuestionCircle, FaRocket } from 'react-icons/fa';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const LinkGroupContainer = makeShortcode("LinkGroupContainer");
const LinkGroup = makeShortcode("LinkGroup");
const LinkGroupCard = makeShortcode("LinkGroupCard");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`minFraud is our transaction risk API, consisting of the minFraud Score, Insights
and Factors services.`}</p>
    <Alert type="info" mdxType="Alert">
      <p>{`If you are a minFraud Legacy customer, please refer to our
`}
        <a {...{
          "href": "/minfraud/minfraud-legacy",
          "parentName": "p"
        }}>{`minFraud Legacy documentation`}</a>
        {`.`}</p>
      <p>{`If you are Proxy Detection Legacy customer, please refer to our
`}
        <a {...{
          "href": "/minfraud/proxy-detection",
          "parentName": "p"
        }}>{`Proxy Detection Legacy documentation`}</a>
        {`.`}</p>
    </Alert>
    <LinkGroupContainer mdxType="LinkGroupContainer">
      <LinkGroup heading="Guides" mdxType="LinkGroup">
        <LinkGroupCard description="Start evaluating your transactions by installing, configuring, and using a minFraud client API." heading="Evaluate a Transaction" icon={FaRocket} to="/minfraud/evaluate-a-transaction" mdxType="LinkGroupCard" />
        <LinkGroupCard description="Use the Report Transaction API to report chargebacks, false positives, suspected fraud, or spam/abuse." heading="Report Transactions" icon={FaBroadcastTower} to="/minfraud/report-a-transaction" mdxType="LinkGroupCard" />
        <LinkGroupCard description="Capture more data and catch more fraud using our JavaScript device tracking library." heading="Integrate Device Tracking" icon={FaShoePrints} to="/minfraud/track-devices" mdxType="LinkGroupCard" />
      </LinkGroup>
      <LinkGroup isCompact heading="Resources" mdxType="LinkGroup">
        <LinkGroupCard description="Learn about each minFraud service's request and response objects." heading="API Documentation" icon={FaBook} to="/minfraud/api-documentation" mdxType="LinkGroupCard" />
        <LinkGroupCard description="Review minFraud release notes to understand changes to the minFraud API." heading="Release Notes" icon={FaClipboardList} to="/minfraud/release-notes" mdxType="LinkGroupCard" />
        <LinkGroupCard description="Consult our knowledge base for articles about using minFraud." heading="Knowledge Base" icon={FaQuestionCircle} to="https://support.maxmind.com/hc/en-us/categories/1260801482329-minFraud-Web-Services" mdxType="LinkGroupCard" />
      </LinkGroup>
    </LinkGroupContainer>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      